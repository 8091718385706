import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initVideo from './modules/video';
import initBg from './modules/bg';

async function init() {
  // Optional polyfills
  // const polyfills = [];
  // if (!('Feature' in window)) polyfills.push(import('feature-polyfill'));
  // await Promise.all(polyfills);

  // Immediate
  initLazyLoad();
  initVideo();
  initBg();

  // Lazyloaded
  // const modules = ['tracking'];

  // for (const module of modules) {
  //   import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  // }

  // Conditionally lazyloaded
  const conditionalModules = {
    '.js-iframe': 'iframe',
    '.js-music': 'music',
    '.js-confetti': 'confetti',
    '.js-slider': 'slider',
    '.js-countdown': 'countdown',
    '.js-form': 'form',
    '.js-accordion': 'accordion',
    '.js-textarea': 'textarea'
  };

  for (const [selector, module] of Object.entries(conditionalModules)) {
    if ($(selector)) import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  }
}

init();
